<template>
  <div>
    <md-card class="md-card-global-sales">
      <md-card-header class="md-card-header-icon md-card-header-blue">
        <div class="card-icon">
          <md-icon>assignment</md-icon>
        </div>
      </md-card-header>

      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <md-button
              class="md-just-icon md-round md-primary pull-right"
              @click="newReason"
            >
              <md-icon>add</md-icon>
              <md-tooltip>New Reason</md-tooltip>
            </md-button>

            <div
              class="d-inline-block pull-right"
            >
              <md-switch
                v-model="filters.show_inactives"
                @change="$refs.vtable.init()"
              >
                Show Inactives
              </md-switch>
            </div>

            <vue-table
              v-if="vTable.headers"
              ref="vtable"
              :values="vTable.values"
              :headers="vTable.headers"
              :actions="vTable.actions"
              :options="{
                tableClass:'table'
              }"
              @changed="getReasons"
              @onEdit="onEdit"
              @onDeactivate="onDeactivate"
              @onActivate="onActivate"
            />
          </div>
        </div>
      </md-card-content>
    </md-card>

    <ReasonsForm
      v-if="showModal"
      :item="selectedReason"
      @close="closeModal"
      @onSaved="$refs.vtable.init()"
    />
  </div>
</template>

<script>
import VueTable from '@/components/Tables/VueTable.vue';
import ReasonsForm from './ReasonsForm.vue';

export default {
  components: {
    VueTable,
    ReasonsForm,
  },
  props: [],
  data() {
    return {
      filters: {
        paginate: true,
        show_inactives: false,
      },
      vTable: {
        headers: [
          {
            title: 'title',
            sortable: true,
          },
        ],
        actions: [
          {
            buttonClass: 'md-warning',
            tooltip: 'Edit',
            callback: 'onEdit',
            icon: 'edit',
          },
          {
            buttonClass: 'md-danger',
            tooltip: 'Deactivate Reason',
            callback: 'onDeactivate',
            icon: 'close',
          },
          {
            buttonClass: 'md-info',
            tooltip: 'Activate Reason',
            callback: 'onActivate',
            icon: 'check',
          },
        ],
        values: {},
      },
      showModal: false,
      selectedReason: null,
    };
  },
  mounted() {
    this.$refs.vtable.init();
  },
  methods: {
    getReasons(params) {
      this.request('programme_cancel_reasons', 'get', { ...params, ...this.filters }, ({ data }) => {
        this.vTable.values = data;
        this.vTable.values.data = this.vTable.values.data.map((x) => {
          x.onDeactivate = x.active;
          x.onActivate = !x.active;
          return x;
        });
      });
    },
    newReason() {
      this.showModal = true;
      this.selectedReason = null;
    },
    onEdit(item) {
      this.showModal = true;
      this.selectedReason = item;
    },
    closeModal() {
      this.showModal = false;
      this.selectedReason = null;
    },
    onDeactivate(item) {
      this.fireConfirm(
        'Deactivating Reason',
        `Are you sure you want to deactivate this Reason (${item.title})?`,
      )
        .then(() => {
          this.request(`programme_cancel_reasons/${item.programme_cancel_reason_id}`, 'put', {
            ...item,
            active: false,
          }, () => {
            this.fireSuccess('Reason deactivated successfully.');
            // Reset table after response
            this.$refs.vtable.init();
          });
        });
    },
    onActivate(item) {
      this.fireConfirm(
        'Activating Reason',
        `Are you sure you want to activate this Reason (${item.title})?`,
      )
        .then(() => {
          this.request(`programme_cancel_reasons/${item.programme_cancel_reason_id}`, 'put', {
            ...item,
            active: true,
          }, () => {
            this.fireSuccess('Reason activated successfully.');
            // Reset table after response
            this.$refs.vtable.init();
          });
        });
    },
  },
};
</script>

<style scoped>
</style>
