<template>
  <vue-modal
    ref="modal"
    size="sm"
    @close="$emit('close')"
  >
    <template slot="title">
      {{ item ? 'Update Reason Form' : 'New Reason Form' }}
    </template>
    <template slot="body">
      <ValidationObserver ref="form">
        <FormText
          v-model="form.title"
          label="Title"
          asterisk
          rules="required"
        />
      </ValidationObserver>
    </template>
    <template slot="footer">
      <md-button
        class="md-success pull-right"
        @click="saveReason"
      >
        Save
      </md-button>
    </template>
  </vue-modal>
</template>
<script>
import { FormText } from '@/components/Inputs';

import { extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

extend('required', required);

export default {
  components: {
    FormText,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    form: {
      title: null,
      active: true,
    },
  }),
  mounted() {
    if (this.item) {
      this.form = this.item;
    }
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },
    saveReason() {
      this.$refs.form.validate().then((res) => {
        if (res) {
          if (this.form.programme_cancel_reason_id) {
            this.request(`programme_cancel_reasons/${this.form.programme_cancel_reason_id}`, 'put', { ...this.form }, () => {
              this.fireSuccess('Reason updated successfuly');
              this.$emit('onSaved');
              this.close();
            });
          } else {
            this.request('programme_cancel_reasons', 'post', { ...this.form }, () => {
              this.fireSuccess('Reason created successfuly');
              this.$emit('onSaved');
              this.close();
            });
          }
        } else {
          this.fireError('Please, complete all the required fields before.');
        }
      });
    },
  },
};
</script>
<style></style>
